@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Press+Start+2P");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  color: #666;
  text-align: left;
  background-color: #EAEAEA; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Press Start 2P", "Open Sans", sans-serif;
  margin-top: 0;
  margin-bottom: 20px; }

p {
  margin-top: 0;
  margin-bottom: 15px; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 400; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0066ff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0066ff;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #666;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 10px; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }

.ir br {
  display: none; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

.form-control {
  display: block;
  width: 100%;
  padding: 12px 20px;
  font-size: 13px;
  line-height: 1.3;
  color: #666;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #666;
    background-color: #fff;
    border-color: #3023AE;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(48, 35, 174, 0.25); }
  .form-control::placeholder {
    color: #e6e6e6;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #f7f7f7;
    opacity: 1; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  a, a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; } }

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden; }

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0; }

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out; }
  .modaal-wrapper * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden; }
  .modaal-wrapper .modaal-close {
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none; }
  .modaal-wrapper.modaal-start_none {
    display: none;
    opacity: 1; }
  .modaal-wrapper.modaal-start_fade {
    opacity: 0; }
  .modaal-wrapper *[tabindex="0"] {
    outline: none !important; }
  .modaal-wrapper.modaal-fullscreen {
    overflow: hidden; }

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }
  .modaal-fullscreen .modaal-outer-wrapper {
    display: block; }

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px; }
  .modaal-fullscreen .modaal-inner-wrapper {
    padding: 0;
    display: block;
    vertical-align: top; }

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 550px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto; }
  .modaal-container.is_loading {
    height: 100px;
    width: 100px;
    overflow: hidden; }
  .modaal-fullscreen .modaal-container {
    max-width: none;
    height: 100%;
    overflow: auto; }

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-close:focus, .modaal-close:hover {
    outline: none;
    background: #fff; }
    .modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
      background: #b93d0c; }
  .modaal-close span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-close:before, .modaal-close:after {
    display: block;
    content: " ";
    position: absolute;
    top: 14px;
    left: 23px;
    width: 4px;
    height: 22px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out; }
  .modaal-close:before {
    transform: rotate(-45deg); }
  .modaal-close:after {
    transform: rotate(45deg); }
  .modaal-fullscreen .modaal-close {
    background: #afb7bc;
    right: 10px;
    top: 10px; }

.modaal-content-container {
  padding: 30px; }

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0; }

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent; }
  .modaal-confirm-btn.modaal-ok {
    padding: 10px 15px;
    color: #fff;
    background: #555;
    border-radius: 3px;
    transition: background 0.2s ease-in-out; }
    .modaal-confirm-btn.modaal-ok:hover {
      background: #2f2f2f; }
  .modaal-confirm-btn.modaal-cancel {
    text-decoration: underline; }
    .modaal-confirm-btn.modaal-cancel:hover {
      text-decoration: none;
      color: #2f2f2f; }

@keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important; }

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent; }

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important; }

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards; }

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px; }

.modaal-image .modaal-container {
  width: auto;
  max-width: 100%; }

.modaal-gallery-wrap {
  position: relative;
  color: #fff; }

.modaal-gallery-item {
  display: none; }
  .modaal-gallery-item img {
    display: block; }
  .modaal-gallery-item.is_active {
    display: block; }

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff; }
  .modaal-gallery-label:focus {
    outline: none; }

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-gallery-control.is_hidden {
    opacity: 0;
    cursor: default; }
  .modaal-gallery-control:focus, .modaal-gallery-control:hover {
    outline: none;
    background: #fff; }
    .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
      background: #afb7bc; }
  .modaal-gallery-control span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    left: 25px;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out; }
  .modaal-gallery-control:before {
    margin: -5px 0 0;
    transform: rotate(-45deg); }
  .modaal-gallery-control:after {
    margin: 5px 0 0;
    transform: rotate(45deg); }

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px; }

.modaal-gallery-next-outer {
  right: 45px; }

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px; }

.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg); }

.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg); }

.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px; }

.modaal-gallery-prev-outer {
  left: 45px; }

.modaal-video-wrap {
  margin: auto 50px;
  position: relative; }

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }
  .modaal-video-container iframe,
  .modaal-video-container object,
  .modaal-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%; }

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block; }

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px; } }

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px; }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7); }
    .modaal-gallery-control:before, .modaal-gallery-control:after {
      background: #fff; }
  .modaal-gallery-next {
    left: auto;
    right: 20px; }
  .modaal-gallery-prev {
    left: 20px;
    right: auto; } }

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; } }

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important; } }

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px; }
  .modaal-instagram iframe {
    width: 600px !important; } }

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px; } }

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important; } }

@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none; } }

.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25); }

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute; }

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff; }

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s; }

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s; }

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s; }

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s; }

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s; }

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s; }

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s; }

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s; }

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0); }

#header {
  text-align: center;
  padding-top: 10px; }

#logo img {
  max-width: 100%;
  height: auto; }

#container:before {
  content: "";
  display: block;
  height: 10px;
  background-image: linear-gradient(to right, #3023AE 0%, #C86DD7 100%); }

#container:after {
  content: "";
  display: block;
  height: 30px;
  background-image: linear-gradient(to right, #3023AE 0%, #C86DD7 100%); }

#container .container-inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 0 auto; }

#main {
  display: flex;
  justify-content: center; }

#game_container {
  flex-shrink: 0;
  background-image: url(../img/iphone.jpg);
  background-size: cover;
  background-position: center top;
  padding-top: 155px;
  padding-left: 55px;
  padding-right: 55px;
  max-width: 100%; }
  @media (max-width: 500px) {
    #game_container {
      padding-left: 30px;
      padding-right: 30px; } }
  @media (max-width: 1199px) {
    .main-home #game_container {
      display: none; } }

#left_section {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (max-width: 1199px) {
    #left_section {
      display: none; } }

.section-title {
  width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  background-image: linear-gradient(to right, #3023AE 0%, #C86DD7 100%);
  font-size: 1rem;
  color: #fff;
  text-align: center; }

.score-section .score-title {
  margin-bottom: 0;
  transform: translateY(50%); }

.score-section .score-inner {
  background-color: #fff;
  box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding-top: 40px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Press Start 2P", "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center; }

.controls-section {
  margin-top: 20px; }

#right_section {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (max-width: 1199px) {
    #right_section {
      display: none; } }
  #right_section p {
    text-align: justify; }

.controls {
  text-align: center;
  margin-bottom: 40px; }
  .controls img {
    width: 60px;
    height: auto; }
  .controls .btn-left {
    margin-left: 5px; }
  .controls .btn-right {
    margin-right: 5px; }

.score-hitlist {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  text-align: left;
  font-size: .75rem; }
  .score-hitlist li {
    display: flex;
    align-items: center;
    padding-top: 3.5px;
    padding-bottom: 3.5px;
    color: gray; }
    .score-hitlist li.active {
      color: #000; }
      .score-hitlist li.active span {
        text-decoration: line-through; }
      .score-hitlist li.active img {
        filter: grayscale(0); }
  .score-hitlist img {
    width: 20px;
    height: auto;
    margin-right: 10px;
    filter: grayscale(100%); }

.share-section {
  display: none; }
  .share-section .btn-share {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0; }

.btn-share {
  display: inline-block;
  text-align: center;
  background-color: #3023AE;
  border: 3px solid #2a1f99;
  border-radius: 10px;
  box-shadow: 2px 2px 0 1px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-family: "Press Start 2P", "Open Sans", sans-serif;
  font-size: .9rem;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  transition: .3s ease; }
  .btn-share:hover, .btn-share:active, .btn-share:focus {
    background-color: #C86DD7;
    color: #fff;
    text-decoration: none;
    outline: none; }

.share-modal-content {
  text-align: center; }
  .share-modal-content h2 {
    width: 350px;
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    background-image: linear-gradient(to right, #3023AE 0%, #C86DD7 100%);
    color: #fff;
    font-size: 1.125rem; }
  .share-modal-content h3 {
    margin-bottom: 1rem; }
  .share-modal-content .share-highscore {
    font-family: "Press Start 2P", "Open Sans", sans-serif;
    font-size: 1.75rem;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem; }

.share-social {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 1rem; }
  .share-social li {
    display: block;
    padding-left: 5px;
    padding-right: 5px; }
  .share-social .share-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #000;
    border-radius: 50%;
    transition: .3s ease; }
    .share-social .share-link:hover, .share-social .share-link:active, .share-social .share-link:focus {
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3); }
    .share-social .share-link img {
      max-width: 25px;
      max-height: 30px;
      width: auto;
      height: auto; }
    .share-social .share-link.share-facebook {
      background-color: #3b5998; }
    .share-social .share-link.share-twitter {
      background-color: #00aced; }

.share-url {
  position: relative; }
  .share-url .form-control {
    padding-right: 60px; }
  .share-url .btn-copy-share {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #3023AE;
    cursor: pointer;
    transition: .3s ease; }
    .share-url .btn-copy-share:hover, .share-url .btn-copy-share:active, .share-url .btn-copy-share:focus {
      color: #C86DD7; }

.share-container {
  max-width: 480px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 40px;
  padding-bottom: 100px;
  text-align: center; }
  .share-container .btn-share {
    width: 250px;
    max-width: 100%;
    margin-top: 70px; }

#section_mobile {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center; }
  @media (min-width: 1200px) {
    #section_mobile {
      display: none; } }
  #section_mobile h2 {
    display: flex;
    align-items: center;
    justify-content: center; }
    #section_mobile h2 img {
      margin-right: 20px; }

/*# sourceMappingURL=style.css.map */