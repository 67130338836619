.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }
.hidden { display: none !important; visibility: hidden; }
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }
.invisible { visibility: hidden; }
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

.form-control {
    display: block;
    width: 100%;
    padding: 12px 20px;
    font-size: $font-size-base;
    line-height: 1.3;
    color: $body-color;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }
  
    &:focus {
        color: $body-color;
        background-color: #fff;
        border-color: $background-primary;
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba($background-primary, 0.25); 
    }
  
    &::placeholder {
        color: lighten($body-color, 50%);
        opacity: 1;
    }
  
    &:disabled,
    &[readonly] {
        background-color: darken(#fff, 3%);
        opacity: 1;
    }
  }