@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Press+Start+2P');

@import 'variables';
@import 'reboot';
@import 'utilities';
@import 'print';

// Modaal
@import 'modaal';


// STYLES
// ---------------------

#header {
    text-align: center;
    padding-top: 10px;
}

#logo {
    img {
        max-width: 100%;
        height: auto;
    }
}

#container {
    &:before {
        content: "";
        display: block;
        height: 10px;
        background-image: linear-gradient(to right, $background-primary 0%, $background-secondary 100%);
    }

    &:after {
        content: "";
        display: block;
        height: 30px;
        background-image: linear-gradient(to right, $background-primary 0%, $background-secondary 100%);
    }
    
    .container-inner {
        max-width: 1200px;
        width: 100%;
        margin: 0 auto 0 auto;
    }
}

#main {
    display: flex;
    justify-content: center;
}

#game_container {
	flex-shrink: 0;
	background-image: url(../img/iphone.jpg);
	background-size: cover;
	background-position: center top;
	padding-top: 155px;
	padding-left: 55px;
    padding-right: 55px;
    max-width: 100%;

    @media (max-width: 500px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    
    .main-home & {
        @media (max-width: 1199px) {
            display: none;
        }
    }
}

#left_section {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 1199px) {
        display: none;
    }
}

.section-title {
    width: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    background-image: linear-gradient(to right, $background-primary 0%, $background-secondary 100%);
    font-size: 1rem;
    color: #fff;
    text-align: center;
}

.score-section {
    .score-title {
        margin-bottom: 0;
        transform: translateY(50%);
    }

    .score-inner {
        background-color: #fff;
        box-shadow: 2px 2px 0 1px rgba(#000, .1);
        border-radius: 15px;
        padding-top: 40px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: $headings-font-family;
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
    }
}

.controls-section {
    margin-top: 20px;
}

#right_section {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding-top: 100px;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 1199px) {
        display: none;
    }
    
    p {
        text-align: justify;
    }
}

.controls {
    text-align: center;
    margin-bottom: 40px;

    img {
        width: 60px;
        height: auto;
    }

    .btn-left {
        margin-left: 5px;
    }

    .btn-right {
        margin-right: 5px;
    }
}

.score-hitlist {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    text-align: left;
    font-size: .75rem;

    li {
        display: flex;
        align-items: center;
        padding-top: 3.5px;
        padding-bottom: 3.5px;
        color: lighten(#000, 50%);

        &.active {
            color: #000;

            span {
                text-decoration: line-through;
            }

            img {
                filter: grayscale(0);
            }
        }
    }

    img {
        width: 20px;
        height: auto;
        margin-right: 10px;
        filter: grayscale(100%);
    }
}

.share-section {
    display: none;

    .btn-share {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.btn-share {
    display: inline-block;
    text-align: center;
    background-color: $background-primary;
    border: 3px solid darken($background-primary, 5%);
    border-radius: 10px;
    box-shadow: 2px 2px 0 1px rgba(#000, .1);
    color: #fff;
    font-family: $headings-font-family;
    font-size: .9rem;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
    transition: .3s ease;

    &:hover, &:active, &:focus {
        background-color: $background-secondary;
        color: #fff;
        text-decoration: none;
        outline: none;
    }
}

.share-modal-content {
    text-align: center;

    h2 {
        width: 350px;
        margin-top: -50px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        background-image: linear-gradient(to right, $background-primary 0%, $background-secondary 100%);
        color: #fff;
        font-size: 1.125rem;
    }

    h3 {
        margin-bottom: 1rem;
    }

    .share-highscore {
        font-family: $headings-font-family;
        font-size: 1.75rem;
        text-shadow: 2px 2px 0 rgba(#000, .3);
        margin-bottom: 2rem;
    }
}

.share-social {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 1rem;

    li {
        display: block;
        padding-left: 5px;
        padding-right: 5px;
    }

    .share-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: #000;
        border-radius: 50%;
        transition: .3s ease;

        &:hover, &:active, &:focus {
            box-shadow: 0 0 3px 2px rgba(#000, .3);
        }

        img {
            max-width: 25px;
            max-height: 30px;
            width: auto;
            height: auto;
        }

        &.share-facebook {
            background-color: #3b5998;
        }
        &.share-twitter {
            background-color: #00aced;
        }
    }
}

.share-url {
    position: relative;

    .form-control {
        padding-right: 60px;
    }

    .btn-copy-share {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding-left: 15px;
        padding-right: 15px;
        background-color: transparent;
        border: none;
        outline: none;
        color: $background-primary;
        cursor: pointer;
        transition: .3s ease;

        &:hover, &:active, &:focus {
            color: $background-secondary;
        }
    }
}

.share-container {
    max-width: 480px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 40px;
    padding-bottom: 100px;
    text-align: center;

    .btn-share {
        width: 250px;
        max-width: 100%;
        margin-top: 70px;
    }
}

#section_mobile {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    @media (min-width: 1200px) {
        display: none;
    }

    h2 {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-right: 20px;
        }
    }
}
