
// Typography
$font-family-base: 'Open Sans', sans-serif;
$font-family-monospace: monospace, monospace;
$headings-font-family: 'Press Start 2P', $font-family-base;
$font-size-base: 13px;
$font-weight-base: 400;
$line-height-base: 1.2;
$headings-margin-bottom: 20px;
$paragraph-margin-bottom: 15px;

// Colour
$body-color: #666;
$body-bg: #EAEAEA;
$background-primary: #3023AE;
$background-secondary: #C86DD7;

$link-color: #0066ff;
$link-hover-color: #0066ff;